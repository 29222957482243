<template>
  <el-row style="width: 100%;">
    <el-form ref="formRef" :model="searchForm" :rules="rules" :inline="true" class="search-form">
      <slot name="before"></slot>
      <template v-for="(item, name) in searchFormItems" :key="name">
        <el-form-item v-if="(item.type && 'hidden' !== item.type) || !item.type" :prop="name" label-width="auto">
          <template #label>
            {{ item.label }}
            <el-tooltip v-if="item.tips" class="item" effect="dark" :content="item.tips" placement="top">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </template>
          <el-input v-if="!item.type || 'input' === item.type" v-model="searchForm[name]" clearable size="small"
                    :placeholder="item.placeholder || '请输入'"/>
          <el-date-picker v-if="['daterange', 'monthrange'].indexOf(item.type) >= 0" v-model="searchForm[name]"
                          value-format="X" size="small" :type="item.watchField ? watchFields[name] : item.type"
                          range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期" unlink-panels
                          :disabledDate="item.disableDate ? item.disableDate : disableDate"></el-date-picker>
          <el-date-picker v-if="['date', 'month', 'year'].indexOf(item.type) >= 0" v-model="searchForm[name]"
                          :type="item.type" value-format="X" size="small" placeholder="选择日期"
                          :shortcuts="item.shortcuts ? item.shortcuts : []"
                          :disabled-date="item.disableDate ? item.disableDate : disableDate"></el-date-picker>
          <el-select v-if="'selector' === item.type" v-model="searchForm[name]" size="small" clearable filterable
                     :remote="item.remote ? true : false"
                     :remote-method="item.remote ? (val) => { fetchOptionsByRemote(val, item, name) } : null"
                     :multiple="item.multiple ? true : false" :multiple-limit="item.multiple"
                     :placeholder="item.placeholder || '请选择'">
            <el-option v-for="item in item.options" :key="item.id" :label="item.name ? item.name : item.name"
                       :value="item.id"></el-option>
          </el-select>
          <account-select v-if="'account-selector' === item.type" v-model="searchForm[name]" size="small" clearable
                          :placeholder="item.placeholder || '请选择'" :multiple="item.multiple ? true : false"/>
          <el-checkbox v-if="'checkbox' === item.type" v-model="searchForm[name]" size="small" :true-label="1"
                       :false-label="0">
            {{ item.name }}
          </el-checkbox>
        </el-form-item>
      </template>
      <span class="search-form-buttons">
        <el-button v-if="showBtn" type="primary" size="small" @click="searchData(true)">查询</el-button>
        <el-button v-if="showBtn" size="small" @click="resetFormItems">重置</el-button>
        <slot name="buttons"></slot>
      </span>
    </el-form>
  </el-row>
</template>

<script setup>
import AccountSelect from "./AccountSelect.vue";
import {getCurrentInstance, onMounted, ref, watch, defineProps, defineEmits, defineExpose, watchEffect} from "vue";

const props = defineProps({
  formItems: {
    default() {
      return {};
    }
  },
  fetchData: {type: Function},
  filterClick: {default: true},
  defaultPageSize: {default: 20},
  showBtn: {default: true}
})

const emit = defineEmits(['refresh', 'reset'])

const {proxy} = getCurrentInstance();
const formRef = ref();
const searchForm = ref({});
const rules = ref({});
const searchFormItems = ref({});
const watchFields = ref({});
onMounted(() => {
  initSearchForm();
})

const fetchOptionsByRemote = (val, item, name) => {
  if (!item.remote) return
  item.remote(val, name.split('_')[0]).then(res => {
    item.options = res && res.length > 0 ? res : []
  })
}

const initSearchForm = (params = undefined) => {
  let defaultParams = params ? params : props.formItems;
  searchForm.value = {};
  searchFormItems.value = {};
  if (defaultParams) {
    for (const item in defaultParams) {
      searchForm.value[item] = defaultParams[item].value ? defaultParams[item].value : null;
      searchFormItems.value[item] = defaultParams[item];

      if (defaultParams[item].watchField) {
        watchFields.value[item] = 'daterange';
      }

      if (defaultParams[item].rule) {
        rules.value[item] = defaultParams[item].rule;
      }

      if (!props.showBtn) {
        watch(() => searchForm.value[item], () => {
          console.log('search 条件变化')
          searchData(true)
        }, {deep: true})
      }
    }

    searchData()
  }
};

const disableDate = () => {
  return false;
};

const searchData = async (resetPage = false) => {
  await formRef.value.validate(valid => {
    if (!valid) return
    if (resetPage) searchForm.value = Object.assign(searchForm.value, {page: 1, pagesize: props.defaultPageSize})
    if (!props.fetchData) {
      emit('refresh', searchForm.value);
    } else {
      props.fetchData(searchForm.value);
    }
  })
}

onMounted(() => {
  for (const item in watchFields.value) {
    watch(() => searchForm.value[item], (newVal) => {
      if (!newVal) return watchFields.value[item] = 'daterange';
      const date = new Date();
      let valueDate = new Date();
      valueDate.setTime(newVal[0]);
      if (Math.abs(valueDate.getFullYear() - date.getFullYear()) > 1) return watchFields.value[item] = 'monthrange';

      valueDate.setTime(newVal[1]);
      if (Math.abs(valueDate.getFullYear() - date.getFullYear()) > 1) return watchFields.value[item] = 'monthrange';
      return watchFields.value[item] = 'daterange';
    })
  }
})

const getSearchForm = () => {
  return searchForm.value ? searchForm.value : {};
}

const resetFormItems = () => {
  if (formRef.value && formRef.value.resetFields) formRef.value.resetFields()
  for (let i in searchForm.value) {
    if (searchForm.value[i] !== null && ['page', 'pagesize'].indexOf(i) < 0) searchForm.value[i] = null
  }
  if (!props.fetchData) {
    emit('refresh', searchForm.value);
  } else {
    props.fetchData(searchForm.value);
  }
  emit('reset')
}

defineExpose({getSearchForm, formRef})
</script>

<style scoped>
.search-form {
  margin-bottom: 10px;
}

.search-form .el-form-item {
  margin-bottom: 10px !important;
}
</style>
